import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import tw, { styled, css } from 'twin.macro'
import SearchBox from '../components/SearchBox'

const Dropdown = styled.select`
  ${tw`uppercase
  text-center
  bg-sand
  rounded-full
  py-3
  px-10
  mt-2
  lg:mt-6
  mb-10
  md:mb-20
  border-b-2
  focus:outline-none
  mr-2
  w-full
  lg:w-72
  appearance-none
  `}
`

const TagList = ({ tags, onChange, tagfilter }) => {
  return (
    /* eslint-disable jsx-a11y/no-onchange */
    <div css={tw`relative inline-block`}>
      <Dropdown name="tags" onChange={onChange} value={tagfilter}>
        <option value="">Vis alle</option>
        {tags.map((tag, i) => {
          return (
            <option key={i} value={tag.slug}>
              {tag.title}
            </option>
          )
        })}
      </Dropdown>
      <div
        css={tw`absolute right-0 flex items-center pr-6 pointer-events-none top-0 mt-6 lg:mt-10`}
      >
        <svg css={tw`w-4 h-4 fill-current`} viewBox="0 0 20 20">
          <path
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
            fillRule="evenodd"
          ></path>
        </svg>
      </div>
    </div>
  )
}

const GridItem = styled.div`
  ${tw`px-4 py-4 block hover:shadow-lg hover:scale-110 transform transition ease-in duration-300`}
  position: relative;
  min-height: 500px;
`

export default function NyhederPage({ data }) {
  const [search, setSearch] = useState('')
  const [tagfilter, setTagfilter] = useState('')

  const pageInfo = data.craftPagesNyhederEntry
  const blogEntries = data.allCraftNyhederNyhederEntry.nodes
  const tags = data.allCraftNewsTag.nodes

  const ogImageOverride = pageInfo.ogImage[0]?.ogimage
  const ogImageGlobal = data.craftHeaderGlobalSet.ogImage[0]?.ogimage

  const ogImage = ogImageOverride || ogImageGlobal

  return (
    <Layout
      pageTitle={pageInfo.title}
      pageDesc={pageInfo.metaDescription}
      ogImage={ogImage}
      darkMenu={pageInfo.darkMenu}
    >
      <div css={tw`min-h-screen flex flex-col items-center`}>
        <h1
          css={tw`text-center mt-40 md:mt-64 mb-10 md:w-96 md:text-5xl text-3xl leading-none`}
        >
          {pageInfo.title}
        </h1>

        <div
          css={tw`text-center container max-w-screen-sm`}
          dangerouslySetInnerHTML={{
            __html: pageInfo.column1,
          }}
        />

        <div>
          <SearchBox
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder="Søg emne"
          />
          <TagList
            tags={tags}
            onChange={(e) => setTagfilter(e.target.value)}
            tagfilter={tagfilter}
          />
        </div>
        <section
          className="grid"
          css={tw`grid md:grid-cols-2 xl:grid-cols-3 gap-8 container mb-16`}
        >
          {blogEntries
            .filter((entry) => {
              const tags = entry.newstags.map((t) => t.slug)
              return (
                entry.title.toLowerCase().includes(search.toLowerCase()) &&
                (tags.includes(tagfilter) || tagfilter === '')
              )
            })
            .map((entry, i) => {
              return (
                <GridItem key={i} className={`gridItem ${i}`}>
                  <Link to={'/' + entry.uri} css={tw`w-full h-full text-navy`}>
                    {entry.dynamicContent[0]?.image[0]?.localFile
                      ?.childImageSharp.gatsbyImageData && (
                      <GatsbyImage
                        image={
                          entry.dynamicContent[0]?.image[0]?.localFile
                            ?.childImageSharp.gatsbyImageData
                        }
                        alt={entry.dynamicContent[0].image[0]?.title}
                        layout="constrained"
                        objectFit="cover"
                        height={250}
                        width={400}
                        imgStyle={{ maxHeight: '200px' }}
                        style={{ maxHeight: '200px' }}
                      />
                    )}

                    <div className="blog-box">
                      <h4
                        css={[
                          tw`py-8`,
                          css`
                            max-width: 80%;
                          `,
                        ]}
                      >
                        {entry.title}
                      </h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: entry.dynamicContent[0].description,
                        }}
                      />
                    </div>
                  </Link>
                </GridItem>
              )
            })}
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query NyhederQuery($slug: String!) {
    craftHeaderGlobalSet {
      ogImage {
        ogimage
      }
    }
    craftPagesNyhederEntry(slug: { eq: $slug }) {
      title
      slug
      darkMenu
      column1
      metaDescription
      ogImage {
        ogimage
      }
    }
    allCraftNewsTag {
      nodes {
        title
        slug
      }
    }
    allCraftNyhederNyhederEntry(sort: { fields: dateCreated, order: DESC }) {
      nodes {
        title
        uri
        newstags {
          ... on Craft_news_Tag {
            title
            slug
          }
        }
        dynamicContent {
          ... on Craft_dynamicContent_heroBlog_BlockType {
            heading
            description
            image {
              title
              ... on Craft_productImages_Asset {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
